import dynamic from 'next/dynamic';
import type { NextPage } from 'next';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';
import { GetStaticProps } from 'next/types';

const LoginApp = dynamic(() => import('@/app/Auth/Login'));
const Home: NextPage = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>Login - Selleri Apps</title>
        <meta name="description" content="Selleri apps is reseller website for selling product" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <LoginApp />
    </>
  );
};
//
export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale || 'id', ['common'])),
  },
  revalidate: true,
});

export default Home;
